import React, { useContext } from "react"
import styled, { css } from "styled-components"

import ContactForm from "$components/ContactForm"
import ContactInfo from "$components/ContactInfo"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"
import { MobileContext } from "$helpers/MobileProvider"

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: block;
  }
`

const ContactInfoContainerStyles = css`
  margin: 16px;
`

const ContactSectionWrapper = styled.div`
  flex: 1;
  margin: 0 40px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    margin: 0;
  }
`

const GoogleMapsIFrame = styled.iframe`
  border: 0;
  width: 100%;
`

const H1 = styled.h1`
  color: ${props => props.theme.nero};
  font-size: 2.5rem;
  font-weight: 700;
  text-align: left;
`

const IFrameWrapper = styled.div`
  min-height: 450px;
`

const MobileHR = styled.hr`
  display: none;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: block;
  }
`

const P = styled.p`
  margin-left: 0;
  margin-right: 0;
  text-align: left;
`

const title = "Contact Us | Lotus Network Solutions"
const description =
  "Contact us today for a quote or enquiry, and we will help you find solutions that best meet your needs."

export default () => {
  const { isMobile } = useContext(MobileContext)

  return (
    <>
      <SEO description={description} title={title} />
      <Header />
      <main>
        <Container>
          <H1>Contact Us</H1>
          <P>
            For any enquiry or quote, please fill in our form or simply get in
            touch directly. We'll have a response within 2 business days.
          </P>
        </Container>
        <ContactContainer>
          <ContactSectionWrapper>
            <ContactForm />
          </ContactSectionWrapper>
          <MobileHR />
          <ContactSectionWrapper>
            <ContactInfo
              containerStyles={ContactInfoContainerStyles}
              fontSize="1.2rem"
              ionIconSize="large"
              lineBreaks={isMobile}
            />
            <IFrameWrapper>
              <GoogleMapsIFrame
                aria-hidden="false"
                height="450"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d808.0386107398749!2d151.13848773162724!3d-33.95106271667604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bb6efc14431d%3A0xc1d620b1aa58b460!2sLotus%20Network%20Solutions!5e0!3m2!1sen!2sau!4v1584598717797!5m2!1sen!2sau"
                tabIndex="0"
                title="LNS Google Maps Embed"
                width="600"
              ></GoogleMapsIFrame>
            </IFrameWrapper>
          </ContactSectionWrapper>
        </ContactContainer>
      </main>
      <Footer />
    </>
  )
}
